<template>
  <div class="p-5">
    <!-- <teacher-header></teacher-header> -->
    <h1 class="my-2" style="margin-left:2.2rem">Dashboard</h1>

    <vx-card
      class="mb-base"
      v-if="this.$store.state.AppActiveUser.role === 'leadTeacher'"
    >
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="mb-5"
      >
        <vs-col class="flex w-full sm:w-1/2 mb-5 md:mb-0">
          <h2 class="mb-0">Teachers</h2>
        </vs-col>
        <vs-col class="flex w-full md:w-1/2">
          <vs-button
            v-if="selectedTeachers.length !== totalTeacherIds.length"
            class="ml-auto"
            @click="selectAllTeachers"
          >
            Select All
          </vs-button>
          <vs-button v-else class="ml-auto" @click="unSelectAllTeachers">
            Unselect All
          </vs-button>
        </vs-col>
      </vs-row>

      <!-- Avatar -->
      <horizontal-scroll>
        <ul class="teacher-list-lead mb-4 outer" v-if="teacherList.length">
          <li
            v-for="(teacher, index) in teacherList"
            v-bind:key="index"
            :class="
              selectedTeachers.includes(teacher._id)
                ? 'relative inline-block active'
                : 'relative inline-block'
            "
            style="max-width:130px;margin-right:40px;text-align:center;"
          >
            <vs-avatar
              size="large"
              :src="teacher.profileImage"
              @click="selectTeacher(teacher, index)"
            />
            <h3
              class="h5"
              style="color: #289AD5; font-size: 14px; line-height: 18px;"
            >
              {{ firstNameOnly(teacher.fullName) }}
            </h3>
          </li>
        </ul>
        <span v-else>
          No Teachers in this room.
        </span>
      </horizontal-scroll>
    </vx-card>
    <room-children></room-children>

    <vx-card class="mb-base">
      <div class="flex flex-wrap">
        <h2>Children's Activities</h2>
        <div class="matrix-btn-wrap ml-auto mb-8"></div>
      </div>

      <!-- Five columns -->
      <vs-row class="bg-grid-color mb-4">
        <vs-col
          vs-type="flex"
          vs-lg-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
          <div class="flex datepicker-wrap">
            <span class="btn btn-outline-primary" @click="previousWeek"
              ><i class="feather icon-chevron-left" ></i
            ></span>
            <span class="reportrange-text">
              <date-picker
                v-model="week"
                type="week"
                placeholder="Select week"
                prefix-class="xmx"
                ref="picker"
                @change="pickerChange"
                :lang="lang"
                :clearable="false"
                :show-week-number="false"
              >
                <template v-slot:input="{ emit }">{{ dateRange }}</template>
              </date-picker>
            </span>
            <span class="btn btn-outline-primary" @click="nextWeek"
              ><i class="feather icon-chevron-right" ></i
            ></span>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="start"
          vs-sm-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="12"
        >
        <ul class="status-list grid grid-cols-2 xl:grid-cols-4 w-full">
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #76c68f;"></span>
              <span class="font-regular">New</span>
            </li>
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #28388d;"></span>
              <span class="font-regular">Spontaneous</span>
            </li>
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #d1d1d1;"></span>
              <span class="font-regular">Complete</span>
            </li>
            <li class="flex flex-row items-center">
              <span class="rounded-full flex-none" style="background: #F3D16C;"></span>
              <span class="font-regular">Follow up</span>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <div class="con-tablex vs-table--content">
        <table
          class="table children-activity-table"
          width="100%"
          v-if="displayView === 'Matrix'"
        >
          <template v-for="(stream, key) in streams">
            <tr v-if="key === 0" :key="key">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0 && key === 0" :key="ckey + 0.1"></td>
                <td v-if="key === 0" class="text-center" :key="ckey + 0.2">
                  <vx-tooltip v-bind:text="skill.description" position="bottom" >
                  <span class="img-block"
                    ><img
                      :src="skill.icon ? skill.icon : $defaultImage"
                      alt=""
                      class="product-img text-center"
                  /></span>
                  <p>{{ skill.name }}</p>
                </vx-tooltip>
                </td>
                <td
                  v-if="ckey > 0 && key > 0"
                  class="text-center"
                  :key="ckey + 0.4"
                ></td>
              </template>
            </tr>
            <tr :key="key + 0.5">
              <template v-for="(skill, ckey) in coreSkills">
                <td v-if="ckey === 0" class="text-center" :key="ckey + 0.3">
                  <vx-tooltip v-bind:text="stream.description" position="right" >
                  <span class="img-block">
                    <img
                      :src="stream.icon ? stream.icon : $defaultImage"
                      alt=""
                      class="product-img"
                  /></span>
                  <p>{{ stream.name }}</p>
                </vx-tooltip>
                </td>
                <td class="text-center" :key="ckey + 0.4">
                  <ul class="status-list">
                    <li
                      v-for="(index, akey) in activityMatrix(
                        skill._id,
                        stream._id
                      )"
                      :key="akey + 0.33"
                      style="display: inline-block"
                    >
                      <!-- <span
                        v-if="index.parentActivityId"
                        @click="
                          index.isCompleted
                            ? showActivityDetail(index)
                            : selectActivity(index)
                        "
                        v-scroll-to="
                          index.isCompleted
                            ? { el: '#activity-detail', offset: -100 }
                            : ''
                        "
                        :class="
                          index.isCompleted
                            ? 'rounded-full complete'
                            : 'rounded-full follow-up'
                        "
                      ></span> -->
                      <span
                        v-if="index.isSpontaneous"
                        @click="
                          index.isSpontaneous
                            ? goToActivity(index)
                            : selectActivity(index)
                        "
                        :class="
                          index.isSpontaneous
                            ? 'rounded-full spontaneous'
                            : 'rounded-full follow-up'
                        "
                      ></span>
                      <span
                        v-else
                        :class="'rounded-full ' + index.status[0].class"
                        @click="
                          index.status[0].slug === 'new'
                            ? selectActivity(index)
                            : showActivityDetail(index)
                        "
                        v-scroll-to="
                          index.status[0].slug === 'new'
                            ? ''
                            : { el: '#activity-detail', offset: -100 }
                        "
                      ></span>
                      <vx-card
                        :class="
                          (key === streams.length - 1 ||
                          key === streams.length - 2 ||
                          key === streams.length - 3 ||
                          ckey === coreSkills.length - 1
                            ? key < 3
                              ? 'vx-card-down'
                              : 'vx-card-up'
                            : '') +
                            (ckey === coreSkills.length - 2
                              ? ' move-right '
                              : '')
                        "
                      >
                        <span class="arrow"></span>
                        <h3>{{ index.name }}</h3>
                        <p>
                          {{
                            index.description.length > 160
                              ? index.description.substr(0, 160) + "..."
                              : index.description
                          }}
                        </p>

                        <ul>
                          <li>
                            <img
                              :src="
                                index.coreSkill.length
                                  ? index.coreSkill[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.waysOfLearning.length &&
                                index.waysOfLearning[0].icon
                                  ? index.waysOfLearning[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                          <li>
                            <img
                              :src="
                                index.streams.length && index.streams[0].icon
                                  ? index.streams[0].icon
                                  : $defaultImage
                              "
                              alt=""
                              class="h-16 object-contain"
                            />
                          </li>
                        </ul>
                      </vx-card>
                    </li>
                  </ul>
                </td>
              </template>
            </tr>
          </template>
        </table>

        <table
          width="100%"
          class="table activity-list-table"
          v-if="displayView === 'List'"
        >
          <thead>
            <tr>
              <th>Activity</th>
              <th>Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody v-for="(activity, key) in activitiesList" :key="key">
            <tr>
              <td vs-align="top" class="font-semibold">{{ activity.name }}</td>
              <td>
                <p>{{ activity.description }}</p>
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.coreSkill.length
                        ? activity.coreSkill[0].icon
                        : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block font-regular">
                    {{
                      activity.coreSkill.length
                        ? activity.coreSkill[0].name
                        : ""
                    }}
                  </p></span
                >
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.waysOfLearning.length
                        ? activity.waysOfLearning[0].icon
                        : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block font-regular">
                    {{
                      activity.waysOfLearning.length
                        ? activity.waysOfLearning[0].name
                        : ""
                    }}
                  </p></span
                >
                <span class="inline-block text-center"
                  ><img
                    :src="
                      activity.streams.length ? activity.streams[0].icon : ''
                    "
                    alt=""
                    width="45px"
                    height="45px;"
                    class="inline-block"
                  />
                  <p class="block font-regular">
                    {{
                      activity.streams.length ? activity.streams[0].name : ""
                    }}
                  </p></span
                >
              </td>
              <td vs-align="top">
                <template v-if="activity.parentActivityId" class="status-list">
                  <span
                    :class="
                      activity.isCompleted
                        ? 'rounded-full complete'
                        : 'rounded-full follow-up'
                    "
                    style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 3px;"
                  ></span>
                  {{ activity.isCompleted ? "Complete" : "Follow up" }}
                </template>
                <template v-else class="status-list">
                  <span
                    :class="'rounded-full ' + activity.status[0].class"
                    style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 3px;"
                  >
                  </span
                  >{{ activity.status[0].name }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
    <vx-card class="mb-base">
      <h2>Learning Stories Overview</h2>
      <vs-row class="title-row">
        <vs-col vs-w="6">
          <h3 class="h4 font-semibold">
            Active Learning Stories: {{ allLearningStories.length }}
          </h3>
        </vs-col>
        <vs-col vs-w="6">
          <h3 class="h4 font-semibold">
            Active Follow Ups: {{ totalFollowUp }}
          </h3>
        </vs-col>
      </vs-row>
      <vs-row
        v-for="(activity, key) in allLearningStories"
        class="content-row"
        :key="key"
      >
        <vs-col vs-w="6">
          <div class="md:flex">
            <h3 class="font-semibold" style="min-width: 130px; width: 130px;">
              <!--{{ daysAgoFromToday(activity.startedAt) }} Days-->
              {{ daysAgoFromToday(activity.createdAt) }} Days
            </h3>
            <div class="mt-2 md:mt-0">
              <h3
                class="h4 font-semibold cursor-pointer"
                @click="
                  activity.status[0].slug === 'new'
                    ? selectActivity(activity)
                    : ''
                "
              >
                {{ activity.name }}
              </h3>
              <p>{{ activity.description }}</p>
            </div>
          </div>
        </vs-col>
        <vs-col vs-w="6">
          <div
            v-for="(childFollowUp, key) in activity.followUp"
            :key="key"
            class="flex align-items-center mb-5"
          >
            <div class="flex flex-wrap md:flex-nowrap">
              <div class="flex items-center">
                <img
                  :src="childFollowUp.child.photo"
                  alt=""
                  width="50px"
                  class="mr-2"
                />
                <p
                  class="h5 font-medium cursor-pointer"
                  @click="
                    childFollowUp.isCompleted
                      ? ''
                      : selectActivity(childFollowUp)
                  "
                >
                  {{ childFollowUp.child.fullName }}
                </p>
              </div>
              <div class="flex mt-5 md: mt-0">
                <span
                  :class="
                    childFollowUp.isCompleted
                      ? 'rounded-full complete'
                      : 'rounded-full follow-up cursor-pointer'
                  "
                  style="width: 17px; height: 17px; border-radius: 50% !important; display: inline-block; margin-right: 15px; position: relative; top: 1px;"
                ></span>

                <a href="#" @click="selectActivity(activity)">
                  {{
                    childFollowUp.isCompleted
                      ? "Complete"
                      : "Active/In Progress"
                  }}
                </a>
              </div>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <div id="activity-detail">
      <activity-detail-page
        v-if="showActivityDetailPage"
        :selected-activity="selectedActivity"
      >
      </activity-detail-page>
    </div>
    <!--<div id="create-form">

      <activity-create-form v-show="activityFormDisplay ===  true">
      </activity-create-form>
    </div>-->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Vue from "vue";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import moment from "moment";
import axios from "../../../http/axios/index";

import ActivityCreateForm from "./activity/ActivityCreateFrom";
// import TeacherHeader from "./TeacherHeader";
import RoomChildren from "./RoomChildren";
// import ToggleSwitch from "vuejs-toggle-switch";

import VueScrollTo from "vue-scrollto";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

import DatePicker from "vue2-datepicker";
import ActivityDetailPage from "./activity/ActivityDetailPage";

require("../../../assets/scss/datepicker.scss");
Vue.use(VueLodash, { name: "custom", lodash: lodash });
import _ from "lodash";

Vue.use(VueScrollTo);

// Vue.use(ToggleSwitch);
export default {
  components: {
    ActivityCreateForm,
    RoomChildren,
    DatePicker,
    ActivityDetailPage,
    HorizontalScroll
  },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      teacher: "",
      learningStories: [],
      allLearningStories: [],
      totalFollowUp: 0,
      week: new Date(),
      dateRange: this.startOfWeek() + " - " + this.endOfWeek(),
      pickerDateRange: "",
      startDate: this.startOfWeek(),
      endDate: this.endOfWeek(),
      coreSkills: "",
      streams: "",
      waysOrLearnings: "",
      activitiesList: "",
      activityFormDisplay: false,
      displayView: "Matrix",
      activityEditFormDisplay: false,
      showActivityDetailPage: false,
      selectedActivity: "",
      myOptions: {
        layout: {
          color: "black",
          backgroundColor: "#e8e8e8",
          borderColor: "#2c98d5",
          fontFamily: "open sans",
          fontWeight: "normal",
          // fontWeightSelected: 'bold',
          squareCorners: false,
          noBorder: false
        },
        size: {
          fontSize: 1,
          padding: 2,
          width: 20
        },
        items: {
          delay: 0.4,
          preSelected: "Matrix",
          disabled: false,
          labels: [
            { name: "Matrix", color: "white", backgroundColor: "#2898D5" },
            { name: "List", color: "white", backgroundColor: "#2898D5" }
          ]
        }
      },
      selectedTeachers: [],
      teacherList: [],
      totalTeacherIds: []
    };
  },
  methods: {
    ...mapActions("activity", [
      "fetchActivities",
      "learningStoryForDashboard",
      "fetchActiveLearningStoryForDashboard",
      "setActivityForParent",
      "setDateRange",
    ]),
    ...mapActions("coreSkill", ["fetchCoreSkills"]),
    ...mapActions("stream", ["fetchStreams"]),
    ...mapActions("waysOfLearning", ["fetchWaysOfLearning"]),
    ...mapActions("room", ["getTeachersByRoomId", "getTeachersByRoomIdFilter"]),
    redirectToFollowup() {},
    pickerChange(value) {
      let sDate = this.startOfWeek(value, true);
      let eDate = this.endOfWeek(value, true);

      this.dateRange = sDate + " - " + eDate;
      this.pickerDateRange = sDate + " - " + eDate;
      this.getLearningStories(sDate, eDate);
    },
    previousWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() - 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }
      this.getLearningStories(this.startDate, this.endDate);
    },
    nextWeek() {
      if (this.pickerDateRange != "") {
        const [pickerStartDate, pickerEndDate] = this.pickerDateRange.split(' - ');
        let d = new Date(pickerStartDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
        this.pickerDateRange = this.startDate + " - " + this.endDate;
      } else {
        let currentDate = this.startDate;
        let d = new Date(currentDate);
        let a = d.setDate(d.getDate() + 7);
        this.startDate = this.startOfWeek(a);
        this.endDate = this.endOfWeek(a);
        this.dateRange = this.startDate + " - " + this.endDate;
      }
      this.getLearningStories(this.startDate, this.endDate);
    },
    startOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);
      let diff =
        date.getDate() -
        date.getDay() +
        (date.getDay() === 0 ? -6 : 1) +
        (fromPicker ? 1 : 0);
      this.week = date;
      return this.moment(date.setDate(diff)).format("MMMM DD YYYY");
      //return new Date(date.setDate(diff)).toISOString().slice(0, 10);
    },
    endOfWeek(paramDate = new Date(), fromPicker = false) {
      let date = new Date(paramDate);

      let lastDate =
        date.getDate() - (date.getDay() - 1) + 6 + (fromPicker ? 1 : 0);

      return this.moment(date.setDate(lastDate)).format("MMMM DD YYYY");
      //return new Date(date.setDate(lastDate)).toISOString().slice(0, 10);
    },
    moment(date) {
      return moment(date);
    },
    goToActivity(activity) {
      this.$router.push({
        path: "/teacher/learning-story/activity/" + activity._id
      });
    },
    async getActivities(
      startDate = this.startOfWeek(),
      endDate = this.endOfWeek()
    ) {
      let param =
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&roomId=" +
        this.teacher.roomId;
      this.$vs.loading();

      await axios.get("teacher/activity" + param).then(activities => {
        this.$vs.loading.close();
        this.activitiesList = activities.data.data;
      });
      this.$vs.loading.close();
    },
    async getLearningStories(
      startDate = this.startOfWeek(),
      endDate = this.endOfWeek()
    ) {
      let param =
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&roomId=" +
        this.teacher.roomId;
      if (this.selectedTeachers.length > 0) {
        param = param.concat(
          `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
        );
      }
      this.$vs.loading();
      await this.learningStoryForDashboard(param)
        .then(res => {
          this.$vs.loading.close();
          this.activitiesList = res.data.data;
          this.learningStories = lodash.filter(this.activitiesList, function(
            activity
          ) {
            return !activity.parentActivityId;
          });
        })
        .catch(err => console.log(err));
      this.$vs.loading.close();
    },
    async getAllActiveLearningStories() {
      let param = "?roomId=" + this.teacher.roomId;

      if (this.selectedTeachers.length > 0) {
        param = param.concat(
          `&teacherIds=${JSON.stringify(this.selectedTeachers)}`
        );
      }
      this.$vs.loading();
      await this.fetchActiveLearningStoryForDashboard(param)
        .then(res => {
          this.$vs.loading.close();
          let allLearningStories = res.data.data;
          let filteredLearningStories = [];
          lodash.forEach(allLearningStories, function(value, key) {
            var completedFollowups = _.filter(value.followUp, function(o) {
              if (o.status[0].slug == "complete") return o;
            }).length;
            if (
              completedFollowups != value.followUp.length ||
              value.followUp.length == 0
            ) {
              filteredLearningStories.push(value);
            }
          });

          this.allLearningStories = filteredLearningStories;

          //this.allLearningStories = res.data.data;
          let $this = this;
          let followUps = 0;
          lodash.forEach(this.allLearningStories, function(value, key) {
            followUps = followUps + value.followUp.length;
          });
          $this.totalFollowUp = followUps;
        })
        .catch(err => console.log(err));
      this.$vs.loading.close();
    },
    activityMatrix(coreSkillId, streamId) {
      return this.lodash.filter(this.activitiesList, {
        coreSkills: coreSkillId,
        stream: streamId
      });
    },
    daysAgoFromToday(date) {
      let dt1 = new Date(date);
      let dt2 = new Date();
      let days = Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );

      if (days < 0) {
        days = 0;
      }
      return days;
    },
    async selectActivity(activity) {
      await this.setActivityForParent(activity);
      await this.setDateRange(this.dateRange);
      this.$router.push({ name: "Documentation" });
    },
    showActivityDetail(activity) {
      this.selectedActivity = activity;
      this.showActivityDetailPage = true;
    },
    getAllTeachersOfRoom(roomId, key) {
      this.getTeachersByRoomId(roomId)
        .then(async res => {
          this.teacherList = res.data.data.filter(
            teacher => teacher._id !== this.$store.state.AppActiveUser._id
          );
          this.totalTeacherIds = this.teacherList.map(item => {
            return item._id;
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    firstNameOnly(fullName) {
      return fullName.split(" ")[0];
    },
    selectAllTeachers() {
      this.selectedTeachers = JSON.parse(JSON.stringify(this.totalTeacherIds));
      this.getLearningStories(this.startDate, this.endDate);
      this.getAllActiveLearningStories();
    },
    unSelectAllTeachers() {
      this.selectedTeachers = [];
      this.getLearningStories(this.startDate, this.endDate);
      this.getAllActiveLearningStories();
    },
    async selectTeacher(val, key) {
      if (this.selectedTeachers.includes(val._id)) {
        _.pull(this.selectedTeachers, val._id);
      } else {
        this.selectedTeachers.push(val._id);
      }
      await this.getLearningStories(this.startDate, this.endDate);
      await this.getAllActiveLearningStories();
      this.activeTeacherKey = key;
      this.selectedTeacher = val.fullName;
    }
  },
  mounted() {
    this.teacher = this.$store.state.AppActiveUser;
    this.getLearningStories(this.startOfWeek(), this.endOfWeek());
    this.getAllActiveLearningStories();
  },
  created() {
    this.fetchCoreSkills()
      .then(res => {
        this.coreSkills = res.data.data;
      })
      .catch(err => console.log(err));

    this.fetchWaysOfLearning()
      .then(res => {
        this.waysOfLearnings = res.data.data;
      })
      .catch(err => console.log(err));

    this.fetchStreams()
      .then(res => {
        this.streams = res.data.data;
      })
      .catch(err => console.log(err));
    this.getAllTeachersOfRoom(this.$store.state.AppActiveUser.roomId);
  }
};
</script>
<style lang="scss">
.new {
  background: #76c68f;
}

.complete {
  background: #d1d1d1;
}

.follow-up {
  background: #f3d16c;
}

.spontaneous {
  background: #28388d;
}

.title-row {
  border-top: 1px solid #d1d1d1;

  .vs-col {
    border-right: 1px solid #d1d1d1;
    padding: 20px;

    &:last-child {
      border-right: none;
    }
  }
}

.content-row {
  .vs-col {
    border-top: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    padding: 20px;

    &:last-child {
      border-right: none;
    }

    .h4 {
      color: #2c2c2c;
      margin-bottom: 10px;
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 30px;
      object-fit: cover;
    }

    .h5 {
      font-weight: 500;
      color: #2c2c2c;
      width: 250px;
    }
  }
}

.teacher-list-lead {
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }

  li {
    display: inline-block;
    text-align: center;
    max-width: 100px;
    margin-right: 40px;
    vertical-align: top;
    @media (max-width: 576px) {
      max-width: 82px;
      margin-right: 10px;
    }

    &.active {
      .con-vs-avatar.large {
        border: 4px solid #289ad5;
        @media (max-width: 576px) {
          border: 2px solid #289ad5;
        }
      }
    }
  }
}
</style>
